import React from "react";
import { textContent } from "../params";
import { SnippetWithBtn, SnippetWithBtnProps, SnippetWithoutBtn, SnippetWithoutBtnProps } from "./Snippets";
import ringBlueIcon from '../media/ringBlueIcon.svg'
import './Section.css'

type SectionWhiteProps = {
    snippets: SnippetWithoutBtnProps[]
}

type SectionBlueProps = {
    snippets: SnippetWithBtnProps[]
}

export const SectionWhite: React.FC<SectionWhiteProps> = (props) => {
    return (
        <div className={`section-white`}>
            <div className={`section-header section-white-header`}>
                <img src={ringBlueIcon} alt="" className={`section-header-icon section-white-header-icon`}/>
                <h2 className={`section-title section-white-title`}>{textContent.ourProjects}</h2>
            </div>
            <div className={`section-text-container section-white-text-container`}>
                {props.snippets.map((snippet, index) => {
                    return (
                        <SnippetWithoutBtn
                            image={snippet.image}
                            mainText={snippet.mainText}
                            name={snippet.name}
                            secondText={snippet.secondText}
                            key={index}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export const SectionBlue: React.FC<SectionBlueProps> = (props) => {
    return (
        <div className={`section-dark`}>
            <div className={`section-header section-dark-header`}>
                <img src={ringBlueIcon} alt="" className={`section-header-icon section-dark-header-icon`}/>
                <h2 className={`section-title section-dark-title`}>{textContent.ourProjects}</h2>
            </div>
            <div className={`section-text-container section-dark-text-container`}>
                {props.snippets.map((snippet, index) => {
                    return (
                        <SnippetWithBtn 
                            btnText={snippet.btnText}
                            image={snippet.image}
                            mainText={snippet.mainText}
                            name={snippet.name}
                            secondText={snippet.secondText}
                            key={index}
                        />
                    )
                })}
            </div>
        </div>
    )
}