import React from "react";
import companyLogo from '../media/logoWhite.svg'
import dividerTop from '../media/vertical-divider-top.svg'
import dividerCenter from '../media/vertical-divider-middle.svg'
import dividerBottom from '../media/vertical-divider-bottom.svg'
import { infoContent, links, textContent } from "../params";
import telegramIcon from '../media/telegram-icon.svg'
import vkIcon from '../media/vk-icon.svg'
import youTubeIcon from '../media/yt-icon.svg'
import whatsappIcon from '../media/whatsapp-icon.svg'
import mailIcon from '../media/mail-icon.svg'
import phoneIcon from '../media/phoneIcon.svg'
import './Footer.css'
import { Link } from "./Link";

export const Footer: React.FC = () => {
    return (
        <footer>
            <div className="footer">
                <img src={companyLogo} alt="" className="footer-logo logo"/>
                <div className="footer-container">
                    <div className="footer-part footer-part-1">
                        <div className="footer-part-container">
                            <p className="text-regular footer-law-text">{textContent.copyRights}</p>
                            <div className="footer-social-links-container">            
                                <a href={links.telegram}>            
                                    <img src={telegramIcon} alt="" className="footer-social-link footer-telegram"/>
                                </a>
                                <a href={links.vk}>
                                    <img src={vkIcon} alt="" className="footer-social-link footer-vk"/>
                                </a>
                                <a href={links.youTube}>
                                    <img src={youTubeIcon} alt="" className="footer-social-link footer-youtube"/>
                                </a>
                                <a href={links.whatsapp}>
                                    <img src={whatsappIcon} alt="" className="footer-social-link footer-whatsapp"/>
                                </a>
                            </div>
                            <p className="footer-designed-by">{textContent.designedBy}</p>
                        </div>
                    </div>
                    <div className="footer-part footer-part-2">
                        <img src={dividerTop} alt="" className="footer-divider footer-divider-top"/>
                        <div className="footer-part-container footer-links">
                            <Link href="" text={textContent.aboutUs.toUpperCase()} type="semi-bold" color="white" additionalClasses={['footer-btns about-us-btn']}/>
                            <Link href="" text={textContent.services.toUpperCase()} type="semi-bold" color="white" additionalClasses={['footer-btns services-btn']}/>
                            <Link href="" text={textContent.production.toUpperCase()} type="semi-bold" color="white" additionalClasses={['footer-btns production-btn']}/>
                            <Link href="" text={textContent.contacts.toUpperCase()} type="semi-bold" color="white" additionalClasses={['footer-btns contacts-btn']}/>
                        </div>
                    </div>
                    <div className="footer-part footer-part-3">
                        <img src={dividerCenter} alt="" className="footer-divider footer-divider-center"/>
                        <div className="footer-part-container">
                            <div className="footer-address-container">
                                <h3 className="footer-address-header">{textContent.address}</h3>
                                <p className="footer-address link-light">{infoContent.address}</p>
                            </div>
                            <div className="footer-contacts-container">
                                <a href={links.email} className="footer-contact-item">
                                    <img src={mailIcon} alt="" className="footer-contact-icon footer-mail-icon"/>
                                    <p className="footer-contact-text link-light">{infoContent.mail}</p>
                                </a>
                                <a href={links.phone} className="footer-contact-item">
                                    <img src={phoneIcon} alt="" className="footer-contact-icon footer-mail-icon"/>
                                    <p className="footer-contact-text link-light">{infoContent.headerPhone}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-part footer-part-4">
                        <img src={dividerBottom} alt="" className="footer-divider footer-divider-bottom"/>
                        <div className="footer-part-container">
                            <div className="footer-subscribe-container">
                                <h4 className="footer-subscribe-title">{textContent.subscribeToNews}</h4>
                                <p className="footer-subscribe-title">{textContent.subscribeToNewsText}</p>
                            </div>
                        </div>
                        <div className="footer-subscribe-container">
                            <input type="email" name="" id="subscribeEmail" placeholder={textContent.email} className="footer-subscribe-input footer-subscribe-input-email"/>
                            <input type="text" name="" id="subscribeName" placeholder={textContent.name} className="footer-subscribe-input footer-subscribe-input-name"/>
                            <input type="email" name="" id="subscribeOrganization" placeholder={textContent.companyOrganization} className="footer-subscribe-input footer-subscribe-input-company"/>
                        </div>
                        <div className="footer-subscribe-agree-container">
                            <input type="checkbox" name="" id="subscribeAgree" className="footer-checkbox"/>
                            <p className="footer-personal-data-agree-text">{textContent.personalDataAgree}</p>
                        </div>
                        <button className="footer-subscribe-btn">{textContent.subscribe}</button>
                    </div>
                </div>
            </div>
        </footer>
    )
}