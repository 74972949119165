import React, { MouseEventHandler } from "react";
import './Button.css'

export type ButtonProps = {
    color: 'white' | 'dark',
    size: 'normal' | 'small',
    text: string,
    additionalClasses?: string[],
    clickCallback?: MouseEventHandler<HTMLButtonElement>,
}

export const Button: React.FC<ButtonProps> = (props) => {
    let classList: string[] = []
    props.color === 'white' ? classList.push('button-white') : classList.push('button-dark');
    props.size === 'small' ? classList.push('little-button') : classList.push();
    if (props.additionalClasses) classList.push(...props.additionalClasses)

    return (
        <button className={classList.join(' ')} onClick={props.clickCallback}>
            {props.text}
        </button>
    )
}