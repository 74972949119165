import { SnippetWithBtnProps, SnippetWithoutBtnProps } from "./components/Snippets"

import img1 from './media/img1.png'
import img2 from './media/img2.png'
import img3 from './media/img3.png'
import img4 from './media/img4.png'
import img5 from './media/img5.png'
import img6 from './media/img6.png'
import img7 from './media/img7.png'
import img8 from './media/img8.png'
import img9 from './media/img9.png'
import img10 from './media/img10.png'
import img11 from './media/img11.png'
import img12 from './media/img12.png'
import img13 from './media/img13.png'
import img14 from './media/img14.png'
import img15 from './media/img15.png'

export const textContent = {
    aboutUs: 'О нас',
    services: 'Услуги',
    production: 'Продукция',
    contacts: 'Контакты',
    bannerMainText: 'Печатные платы',
    bannerSecondText: 'Изготовление обслуживание монтаж',
    askKP: 'Запросить КП',
    snippet1Titile: 'Наши проекты',
    copyRights: 'Правовое соглашение и лицензия являются важными документами, регулирующими использование веб-сайта и его контента. При посещении сайта пользователи автоматически соглашаются с его условиями и правилами. В соглашении подробно описываются права и обязанности обеих сторон, включая правообладателей и пользователей. Лицензионное соглашение определяет,… читать больше',
    designedBy: 'Designed By_AS',
    address: 'Адрес',
    subscribeToNews: 'Подпишитесь на новости',
    subscribeToNewsText: 'Подпишитесь на наши новости и присоединитесь к профессионалам своего дела',
    email: 'E-mail',
    companyOrganization: 'Компания/организация',
    personalDataAgree: 'Согласие на обработку и передачу персональных данных',
    subscribe: 'Подписаться',
    moreInfo: 'Подробнее',
    ourProjects: 'Наши проекты',
    name: 'Имя',

}

export const infoContent = {
    headerPhone: '+7 777 777 77 77',
    address: 'Улица Липовая, дом 123, квартира 45, город Примерный, Почтовый индекс 123456, Россия.',
    mail: 'malito@malito.com',
}

export const links = {
    youTube: '',
    vk: '',
    telegram: '',
    whatsapp: '',
    email: '',
    phone: '',
}

type SnipetsProps = {
    withBtn: SnippetWithBtnProps[],
    withoutBtn: SnippetWithoutBtnProps[],
}

export const snipetsProps: SnipetsProps = {
    withoutBtn: [
        {
            name: '1',
            image: img1,
            mainText: 'Производство электроники',
            secondText: 'От концепции до серийного производства'
        },
        {
            name: '2',
            image: img2,
            mainText: 'Производство электроники',
            secondText: 'От концепции до серийного производства'
        },
        {
            name: '3',
            image: img3,
            mainText: 'Производство электроники',
            secondText: 'От концепции до серийного производства'
        },
    ],
    withBtn: [
        {
            name: '4',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img4,
        },
        {
            name: '5',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img5,
        },
        {
            name: '6',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img6,
        },
        {
            name: '7',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img7,
        },
        {
            name: '8',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img8,
        },
        {
            name: '9',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img9,
        },
        {
            name: '10',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img10,
        },
        {
            name: '11',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img11,
        },
        {
            name: '12',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img12,
        },
        {
            name: '13',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img13,
        },
        {
            name: '14',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img14,
        },
        {
            name: '15',
            mainText: 'Срочные печатные платы',
            secondText: 'От 3-ех дней',
            btnText: textContent.moreInfo,
            image: img15,
        },
    ],
}