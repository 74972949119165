import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Header } from './components/Header';
import { Banner } from './components/Banner';
import { SectionBlue, SectionWhite } from './components/Section';
import { snipetsProps } from './params';
import { Footer } from './components/Footer';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Header />
    <Banner />
    <SectionWhite snippets={snipetsProps.withoutBtn} />
    <SectionBlue snippets={snipetsProps.withBtn}  />
    <Footer />
  </React.StrictMode>
);