import React from "react";
import backgroundImage from '../media/bannerBackgroundImage.png'
import backgroundSvg from '../media/bannerBackground.svg'
import { textContent } from "../params";
import { Button } from "./Button";
import './Banner.css'

export const Banner: React.FC = () => {
    return (
        <div className="banner">
            <img src={backgroundImage} alt="" className="banner-image"/>
            <img src={backgroundSvg} alt="" className="banner-svg"/>
            <div className="banner-content">
                <div className="banner-text">
                    <h1 className="banner-text-main">{textContent.bannerMainText}</h1>
                    <p className="banner-text-second text-bold">{textContent.bannerSecondText}</p>
                </div>
                <Button color="white" size="normal" text={textContent.askKP}/>
            </div>
        </div>
    )
}