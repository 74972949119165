import React from "react";
import ringBlueSvg from '../media/ringBlue.svg'
import './Snippets.css'
import { Button } from "./Button";

export type SnippetWithoutBtnProps = {
    name: string
    image: string,
    mainText: string,
    secondText: string,
}

export type SnippetWithBtnProps = SnippetWithoutBtnProps & {
    btnText: string,
}

export const SnippetWithoutBtn: React.FC<SnippetWithoutBtnProps> = (props: SnippetWithoutBtnProps) => {
    const {secondText, image, mainText, name} = props;
    return (
        <div className={`snippet-type-1 snippet-${name}`}>
            <img src={image} className={`snippet-type-1-img snippet-${name}-img`} alt="" />
            <div className={`snippet-type-1-text-content snippet-${name}-text-content`}>
                <p className={`text-bold snippet-type-1-main-text snippet-${name}-main-text`}>{mainText}</p>
                <p className={`text-regular snippet-type-1-second-text snippet-${name}-second-text`}>{secondText}</p>
            </div>
            <img src={ringBlueSvg} alt="" className={`snippet-type-1-ring snippet-${name}-ring`} />
        </div>
    )
}

export const SnippetWithBtn: React.FC<SnippetWithBtnProps> = (props: SnippetWithBtnProps) => {
    const {secondText, image, mainText, name, btnText} = props;
    return (
        <div className={`snippet-type-2 snippet-${name}`}>
            <img src={image} className={`snippet-type-2-img snippet-${name}-img`} alt="" />
            <div className={`snippet-type-2-text_button snippet-${name}-text_button`}>
                <div className={`snippet-type-2-text-content snippet-${name}-text-content`}>
                    <h4 className={`text-bold snippet-type-2-main-text snippet-${name}-main-text`}>{mainText}</h4>
                    <p className={`text-regular snippet-type-2-second-text snippet-${name}-second-text`}>{secondText}</p>
                </div>
                <Button color="dark" size="small" text={btnText} ></Button>
            </div>
        </div>
    )
}