import React from "react"
import './Link.css'

export type LinkProps = {
    href: string,
    text: string,
    type: 'light' | 'semi-bold',
    color?: 'dark' | 'white',
    clickCallback?: () => void,
    additionalClasses?: string[]
}

export const Link: React.FC<LinkProps> = (props) => {
    let classList: string[] = []
    props.type === 'light' ? classList.push('link-light') : classList.push('link-semi-bold');
    if (props.color === 'white') classList.push('link-white');
    if (props.additionalClasses) classList.push(...props.additionalClasses);
    
    return (
        <a href={props.href} className={classList.join(' ')} onClick={props.clickCallback}>{props.text}</a>
    )
}