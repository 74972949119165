import React from "react";
import companyLogo from '../media/logoWhite.svg'
import phoneIcon from '../media/phoneIcon.svg'
import { textContent, infoContent } from "../params";
import './Header.css'
import { Link } from "./Link";

export const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="header-container">
                <img src={companyLogo} alt="" className="header-logo logo"/>
                <div className="header-links">
                    <Link href="" text={textContent.aboutUs} type="semi-bold" color="white" additionalClasses={['header-btns about-us-btn']}/>
                    <Link href="" text={textContent.services} type="semi-bold" color="white" additionalClasses={['header-btns services-btn']}/>
                    <Link href="" text={textContent.production} type="semi-bold" color="white" additionalClasses={['header-btns production-btn']}/>
                    <Link href="" text={textContent.contacts} type="semi-bold" color="white" additionalClasses={['header-btns contacts-btn']}/>
                    <div className="header-phone">
                        <img src={phoneIcon} alt="" className="header-phone-icon"/>
                        <p className="link-light header-phone-number">{infoContent.headerPhone}</p>
                    </div>
                </div>
            </div>
        </header>
    )
}
